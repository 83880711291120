
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { SelectRecipient } from "../../screens/SelectRecipient";
import { Login } from "../../screens/Login";
import { AddRecipient } from "../../screens/AddRecipient";
import { PayAmount } from "../../screens/PayAmount";
import { PaymentComplete } from "../../screens/PaymentComplete";
import { Transactions } from "../../screens/Transactions";
import { PaymentGateway } from "../../screens/PaymentGateway";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/pay" element={<Login />}></Route>
        <Route path="/pay-amount" element={<PayAmount />}></Route>
        <Route path="/select-recipient" element={<SelectRecipient />}></Route>
        <Route path="/add-recipient" element={<AddRecipient/>}></Route>
        <Route path="/payment-gateway" element={<PaymentGateway />}></Route>
        <Route path="/payment-complete" element={<PaymentComplete />}></Route>
        <Route path="/transactions" element={<Transactions />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
