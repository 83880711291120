import { Box,} from '@mui/material';


// @ts-ignore
import './styles.css';
import addRecipient from './../../assets/addrecipient.png';
import { useNavigate } from 'react-router-dom';
import { BottomTab } from '../../components/BottomTab';
import { NavBar } from '../../components/NavBar';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import whiteArrowIcon from "../../assets/arrow.png";
import { Input } from '../../components/Input';

import { useState } from 'react';

export const PayAmount = () => {

  const navigate=useNavigate();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");

  const doNothing = (event: any) => {
    console.log('do nothing');
    
    const onDismiss = () => {
      setOpen(false);
    };

  };
  return (
    <Box sx={{display:'flex' , alignItems:'center',  flexDirection: 'column', overflow:'hidden'}}>
      <NavBar
        bgColor="white"
        leftItem="back-button"
        customBackRoute={"/"}
        centerItem="text"
        centerText="pay your grocer bill"
        rightItem="help-button"
      />
      <Typography 
            variant={"label"}
            size={"x-large"}
            color="black"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ { marginTop: '10%', marginBottom: '10%' }}
          >
            payment amount
      </Typography>

      <Typography 
            variant={"display"}
            size={"small"}
            color="black"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ { marginTop: '10%', marginBottom: '10%' }}
          >
            {amount}
      </Typography>

    <Button
    text='continue'
    size={"medium"}
    onClick={() => navigate("/select-recipient")}
    bgColor={"black"}
    color={"white"}
    shape="rect"
    width="80%"
    endIcon={<img src={whiteArrowIcon} height="18px" />}
    customStyles={ {position:'absolute', bottom: '6%'} }
      />
      {/* <BottomTab></BottomTab> */}
    </Box>
  );
};
